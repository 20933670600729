import type {CitySelectionStubBlockType} from "~/cms/blocks/CitySelectionStubBlock.vue";

const selectCity = (city: CitySelectionStubBlockType, currentItem: CitySelectionStubBlockType | null) => {
  const cityName: string = city?.city?.toUpperCase() ?? ''
  const citiesList = [
    'НОВОСИБИРСК',
    'ТЮМЕНЬ',
    'ЕКАТЕРИНБУРГ',
  ]

  if (citiesList.includes(cityName)) {
    window.location.href = city?.relocation ?? '';

    return null;
  }
  if (currentItem === city) {
    return null;
  }

  return city;
};

export default selectCity;
